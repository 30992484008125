import { HelpLinksProps } from '../components/help-links/HelpLinks.types';

export const DEFAULT_HELP_LINKS: HelpLinksProps['items'] = [
  {
    href: 'https://www.mobietrain.com/about-us/',
    text: 'About',
  },
  {
    href: 'https://support.mobietrain.com/',
    text: 'Support',
  },
];
